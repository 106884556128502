<template>
  <div id="company-profile">
    <b-row class="d-flex flex-row justify-content-center rounded-lg">
      <b-col>
        <b-row class="rounded-lg p-2">
          <b-col cols="12" class="d-flex flex-row justify-content-between">
            <b-link v-if="mode === 'show'" to="/companies"
              ><button class="btn btn-danger mr-1">
                <span>Back</span>
              </button></b-link
            >
            <b-link
              v-if="mode === 'show' && $can('update', 'Company')"
              :to="`/company/edit/${$route.params.id}`"
              class="btn edit-btn"
            >
              Edit <img src="@/assets/images/icons/edit-3.png" alt="img"
            /></b-link>
          </b-col>
          <b-col cols="12" class="d-flex flex-row justify-content-between">
          </b-col>

          <b-col cols="12" lg="2" class="text-center mt-1">
            <div class="mt-1">
              <div v-if="!image && !company.avatar" class="file myDIV w-100">
                <label for="input-file">
                  <img
                    src="@/assets/images/icons/upload-image.png"
                    alt="upload-img"
                    class="img-fluid"
                  />
                </label>
                <input
                  @change="handleFile($event)"
                  id="input-file"
                  type="file"
                  :disabled="mode == 'show'"
                />
              </div>
              <div v-else class="file myDIV">
                <label for="input-file">
                  <div class="img-border rounded-circle">
                    <img
                      :src="
                        image
                          ? image
                          : company.avatar &&
                            company.avatar.includes('https://ui-avatars.com')
                          ? company.avatar
                          : $store.state.appConfig.imageBaseUrl + company.avatar
                      "
                      alt="document"
                      class="rounded-circle user-img"
                    />
                  </div>
                </label>
                <!-- this is for base64 function call -->
                <input
                  :disabled="mode == 'show'"
                  @change="handleFile($event)"
                  id="input-file"
                  type="file"
                />
              </div>
            </div>
          </b-col>

          <b-col cols="12" md="10" class="mt-2">
            <h3 class="mb-1">Basic Information</h3>
            <hr />
            <validation-observer ref="simpleRules">
              <b-form>
                <b-row>
                  <b-col cols="12" md="6">
                    <label for="">
                      Subsidiary Company Name
                      <span class="text-danger">*</span></label
                    >
                    <b-form-group
                      invalid-feedback="Subsidiary company name is required"
                      :state="nameState"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Subsidiary Company Name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="company.first_name"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Enter Subsidiary Company Name"
                          class="data-text"
                          :readonly="mode === 'show'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <label for="">
                      Contact Number <span class="text-danger">*</span></label
                    >
                    <b-form-group
                      invalid-feedback="Contact number field is required"
                      :state="contactState"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|integer"
                        name="Contact Number"
                      >
                        <b-form-input
                          v-model="company.phone"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Enter Contact Number"
                          type="number"
                          class="data-text"
                          :readonly="mode === 'show'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <label for="Last Name"
                      >Email Address <span class="text-danger">*</span></label
                    >
                    <b-form-group
                      invalid-feedback="Email Address is required"
                      :state="mailState"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <b-form-input
                          v-model="company.email"
                          :state="errors.length > 0 ? false : null"
                          type="email"
                          placeholder="Enter Email Address"
                          class="data-text"
                          :readonly="mode === 'show'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <label for=""> Industry </label>
                    <b-form-group invalid-feedback="Industry field is required">
                      <v-select
                        id=""
                        v-model="SelectedIndustry"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="industries"
                        placeholder="Enter Industry"
                        class="data-text"
                        :disabled="mode === 'show'"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <label for=""> ABN </label>
                    <b-form-group
                      invalid-feedback="ABN number field is required"
                    >
                      <b-form-input
                        v-model="company.info.abn"
                        placeholder="Enter ABN Number"
                        required
                        class="data-text"
                        :readonly="mode === 'show'"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <label for=""> ACN </label>
                    <b-form-group
                      invalid-feedback="ACN number field is required"
                    >
                      <b-form-input
                        v-model="company.info.acn"
                        placeholder="Enter ACN Number"
                        required
                        class="data-text"
                        :readonly="mode === 'show'"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <label for=""> TFN </label>
                    <b-form-group
                      invalid-feedback="TFN number field is required"
                    >
                      <b-form-input
                        v-model="company.info.tfn"
                        placeholder="Enter TFN Number"
                        required
                        class="data-text"
                        :readonly="mode === 'show'"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <label for=""> Commercial Address </label>
                    <b-form-group
                      invalid-feedback="Commercial Address  field is required"
                    >
                      <b-form-input
                        v-model="company.info.address"
                        placeholder="Enter Commercial Address "
                        required
                        class="data-text"
                        :readonly="mode === 'show'"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <label for=""> Suburb </label>
                    <b-form-group invalid-feedback="Suburb field is required">
                      <b-form-input
                        v-model="company.info.city"
                        placeholder="Enter Your Suburb"
                        required
                        class="data-text"
                        :readonly="mode === 'show'"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <label for=""> State </label>
                    <b-form-group invalid-feedback="State field is required">
                      <b-form-input
                        v-model="company.info.state"
                        placeholder="Enter Your State"
                        required
                        class="data-text"
                        :readonly="mode === 'show'"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col class="text-right">
                    <router-link
                      v-if="mode === 'edit' || mode === 'create'"
                      to="/companies"
                      ><button class="btn btn-danger mr-1">
                        <span>Cancel</span>
                      </button></router-link
                    >
                    <button
                      @click.prevent="handleSubmit"
                      v-if="mode === 'edit' && $can('update', 'Company')"
                      class="btn edit-btn"
                    >
                      Update
                    </button>
                    <button
                      @click.prevent="handleSubmit"
                      v-if="mode === 'create' && $can('create', 'Company')"
                      class="btn edit-btn"
                    >
                      Create
                    </button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <validation-observer ref="passwordForm">
      <b-form class="mx-1">
        <b-row v-if="mode === 'edit' && $can('change password', 'Company')">
          <b-col cols="2"></b-col>
          <b-col cols="10" class="py-2">
            <b-button
              v-b-modal.modal-1
              class="password-btn btn shadow w-100 d-flex justify-content-between align-items-center border-0"
            >
              Change Password
              <img src="@/assets/images/icons/lock.png" alt="" />
            </b-button>
          </b-col>

          <b-col cols="12">
            <b-modal id="modal-1" title="Change Password" hide-footer>
              <p class="text-center modal-title">Rules</p>
              <p class="text-center modal-sub-title">
                A - Z | a - z | 0 - 9 | Special Character
              </p>
              <b-row>
                <b-col
                  v-if="logedInUser.roles[0].name === 'customer'"
                  cols="12"
                >
                  <b-form-group
                    invalid-feedback="Old Password Is required"
                    :state="oldPassword"
                  >
                    <label for="">
                      Existing Password
                      <span class="text-danger">*</span></label
                    >
                    <validation-provider
                      #default="{ errors }"
                      name="Old Password"
                      vid="OldPassword"
                      rules="required|min:8"
                    >
                      <b-form-input
                        id="a-password"
                        v-model="passwordForm.old_password"
                        :state="errors.length > 0 ? false : null"
                        type="password"
                        placeholder="Type your old password as condition"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    invalid-feedback="New Password Is required"
                    :state="newPassword"
                  >
                    <label for="">
                      New Password <span class="text-danger">*</span></label
                    >
                    <validation-provider
                      #default="{ errors }"
                      name="New Password"
                      vid="NewPassword"
                      rules="required|min:8"
                    >
                      <b-form-input
                        id="ac-password"
                        v-model="passwordForm.password"
                        :state="errors.length > 0 ? false : null"
                        type="password"
                        placeholder="Type your new password as condition"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <label for="">
                    Re-Type New Password
                    <span class="text-danger">*</span></label
                  >
                  <b-form-group
                    invalid-feedback="Re-Type Password Is Required"
                    :state="againNewPassword"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Re-Type New Password"
                      vid="ReTypePassword"
                      rules="required|min:8"
                    >
                      <b-form-input
                        id="ar-password"
                        v-model="passwordForm.password_confirmation"
                        :state="errors.length > 0 ? false : null"
                        type="password"
                        placeholder="Re-Type your new password as condition"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col class="text-center">
                  <button
                    @click="$bvModal.hide('modal-1')"
                    class="btn mx-1 cancel"
                  >
                    Cancel
                  </button>
                  <button @click.prevent="updatePassword" class="btn mx-1 save">
                    Save
                  </button>
                </b-col>
              </b-row>
            </b-modal>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { getLogedInUser } from "@/utils/auth";
import { toast } from "@/utils/toast";
import { email, required } from "@validations";
import {
BButton,
BCard,
BCol,
BForm,
BFormCheckbox,
BFormCheckboxGroup,
BFormDatepicker,
BFormFile,
BFormGroup,
BFormInput,
BFormSelect,
BFormTextarea,
BLink,
BRow,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";
export default {
  props: {
    customer: {
      type: Object,
      default: () => ({}),
    },
    company: {
      type: Object,
      default: () => ({}),
    },
    mode: {
      type: String,
      default: "show",
    },
    CreateFrom: {
      type: String,
      default: "customer",
    },
  },
  components: {
    BButton,
    BCard,
    BCol,
    BForm,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BRow,
    BLink,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      logedInUser: JSON.parse(getLogedInUser()),
      passwordForm: {
        id: this.$route.params.id,
        old_password: "",
        password: "",
        password_confirmation: "",
      },
      SelectedIndustry: null,
      nameState: null,
      mailState: null,
      contactState: null,
      phone: null,
      email: null,
      industries: [],
      loading: false,
      image: null,
      // password validation section
      oldPassword: null,
      newPassword: null,
      againNewPassword: null,
      email,
      required,
    };
  },
  created() {
    this.getIndustries();
  },
  mounted() {
    setTimeout(() => {
      this.loading = true;
      if (this.$route.params.id) {
        this.loading = true;
        this.SelectedIndustry = this.industries.find(
          (industries) => industries.id == this.company.info.industry_id
        );
        this.loading = false;
      }
    }, 2000);
  },
  methods: {
    updatePassword() {
      this.$refs.passwordForm.validate().then((success) => {
        if (success) {
          this.$store
            .dispatch("user/updateUserPassword", this.passwordForm)
            .then((res) => {
              if (res.status === 200) {
                this.$bvModal.hide("modal-1");
                toast(
                  "Successfully Updated",
                  "CheckCircleIcon",
                  "success",
                  "Password Updated"
                );
              } else {
                toast(
                  "Error",
                  "AlertTriangleIcon",
                  "error",
                  "Password Not Updated"
                );
              }
            })
            .catch((error) => {
              toast(
                "Error",
                "AlertTriangleIcon",
                "error",
                error.response?.data?.message || "Password Not Updated"
              );
            });
        } else {
          toast(
            "Validation Error",
            "AlertTriangleIcon",
            "danger",
            "Please fill all the required fields"
          );
        }
      });
    },
    handleSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          if (this.mode === "edit") {
            this.updateCompany();
          } else {
            this.registerCompany();
          }
        } else {
          toast(
            "Validation Error",
            "AlertTriangleIcon",
            "danger",
            "Please fill all the required fields"
          );
        }
      });
    },

    registerCompany() {
      if (this.SelectedIndustry) {
        this.company.industry = this.SelectedIndustry.id;
      }
      this.company.city = this.company.info?.city;
      this.company.state = this.company.info?.state;
      this.company.abn = this.company.info?.abn;
      this.company.acn = this.company.info?.acn;
      this.company.tfn = this.company.info?.tfn;
      this.company.address = this.company.info?.address;
      this.company.avatar = this.image;
      this.$store
        .dispatch("company/companyRegistration", this.company)
        .then((response) => {
          if (response.status_code === 200) {
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "Subsidiary Company Created Successfully."
            );
            if (this.CreateFrom == "customer") {
              this.$emit("getCompanies");
            }
          }
        })
        .catch((error) => {
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            error.response?.data?.message ||
              "Error in Creating Subsidiary Company!"
          );
        });
    },
    updateCompany() {
      if (this.SelectedIndustry) {
        this.company.industry = this.SelectedIndustry.id;
      }
      this.company.city = this.company.info?.city;
      this.company.state = this.company.info?.state;
      this.company.abn = this.company.info?.abn;
      this.company.acn = this.company.info?.acn;
      this.company.tfn = this.company.info?.tfn;
      this.company.address = this.company.info?.address;
      this.company.avatar = this.image ? this.image : this.company.avatar;

      this.$store
        .dispatch("company/updateCompany", this.company)
        .then((response) => {
          if (response.status_code === 200) {
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "Subsidiary Company Updated Successfully."
            );
            // this.$router.push({ name: "companies" });
          }
        })
        .catch((error) => {
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            error.response?.data?.message ||
              "Error in Updating Subsidiary Company!"
          );
        });
    },

    getIndustries() {
      let query = {
        limit: 1000,
      };
      this.$store.dispatch("industry/getIndustries", query).then((response) => {
        this.industries = response.data.data;
      });
    },
    handleFile(e) {
      let file = e.target.files[0];
      // make base64 image for show preview
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.image = reader.result;
      };
    },
  },
};
</script>

<style scoped>
.cancel {
  background: rgba(255, 79, 93, 0.1);
  color: #ff4f5d;
}

.save {
  background: #0bbe32;
  color: white !important;
}

.password-btn {
  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
}

.update-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
  color: white;
}

.edit-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  color: white;
}

.data-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;

  color: #01185e;
}

.file {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.file > input[type="file"] {
  display: none;
}

.file > label {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@keyframes pulse {
  0% {
    color: hsl(48, 100%, 67%);
  }

  50% {
    color: hsl(48, 100%, 38%);
  }

  100% {
    color: hsl(48, 100%, 67%);
  }
}

.hide {
  display: none;
  position: absolute;
  margin-top: -100px;
}

.myDIV:hover + .hide {
  display: block;
  color: red;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.img-border {
  padding: 10px;
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  height: 160px;
  width: 160px;
}

.user-img {
  background-color: white;
  padding: 8px;
  width: 100%;
  height: 100%;
  /* object-fit: cover;
  object-position: center; */
}

.modal-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 43px;
  text-align: center;
  color: #515b60;
}

.modal-sub-title {
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  text-align: center;
  color: #79869f;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
